<!--
 * @Author: Trevor
 * @Github: https://github.com/TrevorYS
 * @Date: 2021-04-21 09:48:00
 * @LastEditors: Trevor
 * @LastEditTime: 2021-04-26 14:18:15
-->
<template>
  <div class="home">
    <div class="loading" v-if="!mapFinished">
      <div style="margin-bottom: 70px">
        <img src="@/assets/loading.gif" />
        <p>地图加载中...</p>
      </div>
    </div>
    <div class="home__container">
      <div class="container" :style="mapStyle">
        <div id="mapContainer" :style="mapStyle"><!--地图--></div>
      </div>
      <div class="opts">
        <!--当前位置-->
        <div class="icon dang" @click="handleCurrentLocation">
          <img src="@/assets/dang.png" alt="" />
        </div>
      </div>
    </div>
    <!-- poi -->
    <div class="poi" v-if="currentPoi">
      <div class="poi-click" :style="POIStyleObj">
        <div class="titleimg">
          <img :src="currentPoiInfo.imageUrl" alt="" />
        </div>
        <div class="TTimg">
          <div class="imgtitle">
            <div>
              <span class="title">{{ currentPoiInfo.pointName }}</span>
              <span class="personNum"></span>
            </div>
            <div class="spot" v-if="currentPoiInfo.spotProAttractions">
              {{ currentPoiInfo.spotProAttractions.openStartTime }} -
              {{ currentPoiInfo.spotProAttractions.openEndTime }}
            </div>
            <div
              class="spot"
              v-if="
                currentPoiInfo.spotProAttractions &&
                currentPoiInfo.spotProAttractions.openHours
              "
            >
              {{
                currentPoiInfo.spotProAttractions.openHours | replaceUnderline
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { Toast, Loading } from 'vant'
import mixinsMap from '@/utils/map'
import { loadPoints, getCardInfo } from '@/api/index'
import { checkURL } from '@/utils/validate'

export default {
  name: 'RecommendPath',
  mixins: [mixinsMap],
  components: {
    Loading,
  },
  data() {
    return {
      currentPoi: null,
      lineInfo: [],
      POIStyleObj: {},
      mapStyle: {},
      isPlayVoice: false,
      isNeedDraw: true,
      cardPointId: '',
    }
  },
  created() {
    const { scenicCode, scenicName, cardPointId } = this.$route.query
    this.scenicCode = scenicCode || 'S001'
    this.scenicName = scenicName || '石燕湖'
    this.cardPointId = cardPointId
    this.wxConfig()
    this.getData()
  },
  mounted() {
    this.importJmapJS()
  },
  beforeDestroy() {
    this.map = null
    clearInterval(this.time)
  },
  methods: {
    getData() {
      if (this.cardPointId) {
        getCardInfo(this.cardPointId).then((res) => {
          const { data } = res
          data.cardPointInfos.map((cItem) => {
            let { spotPro } = cItem
            spotPro.imageUrl = checkURL(spotPro.imageUrl)
              ? spotPro.imageUrl
              : `${process.env.VUE_APP_IMAGEURL}${spotPro.imageUrl}`
            spotPro.layerDefaultIcon = checkURL(spotPro.layerDefaultIcon)
              ? spotPro.layerDefaultIcon
              : `${process.env.VUE_APP_IMAGEURL}${spotPro.layerDefaultIcon}`
            spotPro.layerFocusIcon = checkURL(spotPro.layerFocusIcon)
              ? spotPro.layerFocusIcon
              : `${process.env.VUE_APP_IMAGEURL}${spotPro.layerFocusIcon}`
            this.lineInfo.push(spotPro)
          })
        })
      }
    },
    dealWithMapInfo() {
      this.map.on('EVENT_MAP_INIT_FINISH', () => {
        this.mapFinished = true
        this.soundObj = JMap.getSoundObj() || null
        this.time = setInterval(() => {
          this.throttle(this.getLocationFromWx())
        }, 2000)
      })
    },
    drawLine() {
      if (this.mapFinished) {
        this.markerList = []
        this.map.showCustomPoiList(this.markerList, false, true, true)
        const drivingObj = new JMap.Driving({ map: this.map })
        this.lineInfo.filter((item) => {
          item.poiId = { objectId: 0, scenicId: 0 }
          item.lnglat = { lng: item.lng, lat: item.lat }
          const markerObj = new JMap.Marker({
            name: item.name,
            lnglat: new JMap.LngLat(item.lng, item.lat),
            icon: item.layerDefaultIcon,
            size: new JMap.Size(100, 68),
            offset: new JMap.Pixel(35, 60),
            iconC: item.layerFocusIcon,
            sizeC: new JMap.Size(100, 82),
            offsetC: new JMap.Pixel(36, 61),
            visible: true,
            poiObj: {
              type: '0',
              poiId: this.map.getScenicObjectId(),
              typePs: '',
              details: item,
            },
            mapObj: this.map,
          })
          this.markerList.push(markerObj)
          return item
        })
        this.map.showCustomPoiList(this.markerList, false, true, true)
        drivingObj.search(
          {
            type: 6,
            planCond: {},
            fileName: '',
            armList: {
              armCount: this.lineInfo.length,
              poiList: this.lineInfo,
            },
          },
          (status, searchResult) => {
            console.log('status:', status)
            if (status === 'complete') {
              const create_route = searchResult
              this.map.showRouteInfo(create_route.guidePath[0])
              this.isNeedDraw = false
            }
          }
        )
      }
    },
    handleDistance() {
      const { scenicId, lat, lng, id } = this.lineInfo[0]
      const drivingObj = new JMap.Driving({ map: this.map })
      const cur = this.map.getCarPos()
      const posList = [
        {
          poiId: { scenicId: 0, objdectId: 0 },
          lnglat: { lng: cur.lng, lat: cur.lat },
        },
        {
          poiId: { scenicId: scenicId, objdectId: id },
          lnglat: { lng: lng, lat: lat },
        },
      ]
      drivingObj.search(
        {
          type: 6,
          planCond: {},
          fileName: '',
          armList: { armCount: 2, poiList: posList },
        },
        (status, searchResult) => {
          if (status === 'complete') {
            if (!searchResult) {
              Toast('当前位置不在景区内')
            }
            const routeInfo = searchResult.guidePath[0]
            this.map.showRouteInfo(routeInfo)
            this.isNeedDraw = false
          } else {
            Toast('当前位置不在景区内')
            this.isNeedDraw = true
          }
        }
      )
      // console.log('this.currentPoiInfo:', this.currentPoiInfo)
    },
    handleCurrentLocation() {
      this.getLocationFromWx()
      if (this.currentLocationInfo.length !== 0) {
        this.map.setCenter(this.currentLocationInfo)
      }
    },
    getLocationTodo() {
      if (this.isNeedDraw) {
        if (this.lineInfo && this.lineInfo.length > 1) {
          this.drawLine()
        } else if (this.lineInfo && this.lineInfo.length === 1) {
          this.handleDistance()
        }
      }
    },
  },
}
</script>
 
<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  position: relative;
  .loading {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      display: block;
      width: 90px;
      height: 90px;
    }
    p {
      font-size: 16px;
      color: #ccc;
    }
  }
  &__container {
    width: 100%;
    height: 100%;
  }
  .choice {
    width: 100%;
    height: 0.8rem;
    padding: 0.15rem 0;
    box-sizing: border-box;
    overflow: hidden;
    overflow-x: scroll;
    .con {
      display: block;
      width: 590px;
      height: 0.5rem;
      font-size: 0;
      padding: 0 1rem 0 0.2rem;
      overflow: hidden;
      white-space: nowrap;
      li {
        display: inline-block;
        font-size: 0.25rem;
        line-height: 0.5rem;
        padding: 0 0.2rem;
        margin: 0 0.2rem;
        color: #000;
        font-size: 0.28rem;
        border: 0;
        border-radius: 0.25rem;
      }
      .on {
        font-size: 0.3rem;
        background-color: #fd7d6f;
        color: #fff;
      }
    }
  }
  .search {
    position: absolute;
    right: 0;
    top: 0;
    width: 1.18rem;
    height: 0.8rem;
    img {
      width: 100%;
      height: 100%;
      vertical-align: top;
    }
  }
  .container {
    position: absolute;
    color: #000;
    border: 0px solid #000;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    z-index: 0;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  #mapContainer {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
  }
  .lu {
    width: 2.14rem;
    height: 0.98rem;
    position: absolute;
    right: 0;
    top: 1rem;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .bak {
    width: 1.5rem;
    height: 0.98rem;
    position: absolute;
    right: 0;
    top: 2rem;
  }
  .opts {
    position: absolute;
    right: 0.1rem;
    bottom: 1rem;
    .icon {
      width: 0.88rem;
      height: 0.88rem;
      img {
        display: block;
        width: 100%;
      }
    }
  }

  .cover {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 999;
    .shaiCon {
      width: 100%;
      height: auto;
      h5 {
        margin: 0.2rem 0;
        padding-left: 0.2rem;
        text-align: center;
        font-weight: normal;
        font-size: 0.3rem;
      }

      .con {
        width: 5.5rem;
        height: 5.5rem;
        position: absolute;
        left: 50%;
        top: 50%;
        background: #fff;
        padding: 0.4rem;
        box-sizing: border-box;
        margin: -2.75rem 0 0 -2.75rem;
        z-index: 99;
      }
      .close {
        width: 0.8rem;
        height: 0.8rem;
        position: absolute;
        right: 0.3rem;
        top: 0.4rem;
        img {
          display: block;
          width: 100%;
        }
      }
      .item {
        float: left;
        width: 25%;
        padding: 0.2rem;
        box-sizing: border-box;
        height: auto;
        overflow: hidden;
        text-align: center;
        font-size: 0.24rem;
        white-space: nowrap;
        img {
          display: block;
          width: 100%;
          margin-bottom: 0.1rem;
        }
      }
    }
    .three {
      .close {
        top: 1.6rem;
      }
      .item {
        width: 33.33%;
        img {
          display: block;
          width: 100%;
          margin-bottom: 0.1rem;
        }
      }
    }
    .lines {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      /*height: 5rem;*/
      background: #fff;
      .con {
        height: 3.6rem;
        overflow-y: scroll;
        span {
          float: right;
          color: #999;
          i {
            color: rgb(253, 125, 111);
            margin-right: 0.05rem;
            font-style: normal;
          }
        }
      }
      .item {
        border-bottom: solid 1px rgb(237, 237, 237);
        height: 1.2rem;
        overflow: hidden;
        font-size: 0.28rem;
        line-height: 1.2rem;
        padding: 0rem 0.3rem;
        color: rgb(102, 102, 102);
      }
      .title {
        color: rgb(51, 51, 51);
        font-size: 0.36rem;
        height: 1.2rem;
        line-height: 1.2rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 0.3rem;
        border-bottom: solid 1px #ededed;
        img {
          float: left;
          display: block;
          width: 0.39rem;
          height: 0.35rem;
          margin-right: 0.2rem;
        }
      }
    }
  }
}
.poi {
  display: inline-block;
  width: auto;
  // position: relative;
  z-index: 10;
  .poi-click {
    width: auto;
    padding: 0.2rem 0.2rem;
    background: #fff;
    box-sizing: border-box;
    border-radius: 0.2rem;
    font-size: 0;
    position: absolute;
    margin-top: -0.3rem;
    margin-left: -1.5rem;
    display: none;
    flex-direction: row;
    top: -30rem;
    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 0.18rem solid transparent;
      border-right: 0.18rem solid transparent;
      border-top: 0.26rem solid #fff;
      position: absolute;
      left: 50%;
      top: 100%;
      margin-left: -0.09rem;
    }
  }
  .titleimg {
    width: 1rem;
    height: 1rem;
    margin: 0.1rem 0.2rem 0 0.1rem;
    img {
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }
  }

  .titleimg,
  .TTimg {
    // float: left;
    display: inline-block;
  }
  .imgtitle {
    font-size: 0.3rem;
    margin-bottom: 0.1rem;
    .title {
      padding-left: 0.15rem;
    }
  }
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.28rem;
    width: 1.68rem;
    height: 0.68rem;
    line-height: 0.68rem;
    text-align: center;
    overflow: hidden;
    color: #fff;
    border-radius: 0.34rem;
    margin: 0 0.05rem;
    img {
      display: inline-block;
      width: 0.23rem;
      height: 0.23rem;
      margin: 0 0.1rem 0 0.2rem;
      vertical-align: middle;
    }
  }
  .spot {
    color: #999999;
    font-size: 0.28rem;
    padding-left: 0.15rem;
    white-space: nowrap;
  }
  .bottoms {
    display: flex;
    flex-direction: row;
  }
  .voice {
    background-color: #289cff;
  }
  .gothere {
    background-color: #ffb128;
  }
}
.bottom-container {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 998;
  .routeline {
    display: block;
    background: #fff;
    padding: 0.4rem 0.3rem;
    font-size: 0;
    .title {
      font-size: 0.34rem;
      height: 0.6rem;
      overflow: hidden;
      line-height: 0.6rem;
      text-align: left;
      color: #aaaaaa;
    }
    .item {
      display: inline-block;
      line-height: 0.8rem;
      font-size: 0.36rem;
    }
    .time {
      margin-right: 0.4rem;
    }
    .spendTime,
    .distance {
      display: inline-block;
      line-height: 0.8rem;
    }
    .guide {
      float: right;
      display: block;
      width: 2.5rem;
      height: 0.8rem;
      line-height: 0.8rem;
      background: #ea8677;
      color: #fff;
      text-decoration: none;
      border: 0;
      border-radius: 0.5rem;
      font-size: 0.34rem;
      box-sizing: border-box;
      text-align: center;
    }
  }
  .sPointContainer {
    display: block;
    height: 0.8rem;
    position: relative;
    background: #fff;
    padding-left: 1rem;
    font-size: 0;
    .play,
    .close {
      position: absolute;
      line-height: 0.6rem;
    }
    .play {
      width: 0.6rem;
      height: 0.6rem;
      left: 0.2rem;
      top: 0.1rem;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .name {
      display: inline-block;
      font-size: 0.28rem;
      color: #000;
      line-height: 0.8rem;
      padding-right: 0.5rem;
      position: relative;
    }
    .icon-right {
      position: absolute;
      right: 0;
      top: 0.3rem;
      width: 0.13rem;
      height: 0.25rem;
      background: url('../../assets/icon-right.png') right center no-repeat;
    }
    .close {
      right: 0.2rem;
      top: 0.35rem;
      width: 0.15rem;
      height: 0.15rem;
      padding: 0.3rem 0.1rem;
      transform: translateY(-40%);
    }
  }
}
.offthis {
  text-align: center;
  line-height: 0.98rem;
  width: 3.2rem;
  height: 0.98rem;
  border-radius: 1rem;
  color: #fd7d6f;
  background: white;
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  // bottom: 0.58rem;
  bottom: 20px;
  font-size: 0.32rem;
}
</style>